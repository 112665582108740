import {getHoursMinute} from "@/views/ShopAdmin/AddLimitTimeDate/util";


export const keyList = [
    "limitStartTime",
    "limitEndTime",
    // "school"
]


export const timeList = [
    {
        values:getHoursMinute(24).map(item=>({ ...item,state:item.text,text:item.text + " 时" })),
        defaultIndex:0
    },{
        values:[":"],
        defaultIndex:0
    },{
        values:getHoursMinute(60).map(item=>({ ...item,state:item.text,text:item.text + " 分" })),
        defaultIndex:0
    },
]
