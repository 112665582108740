import {addLimitTimeDate, updateLimitTimeDate} from "@/Api/ShopAdmin";
import Storage from "@/util/Storage";
import {Toast} from "vant";
import {keyList} from "@/views/ShopAdmin/AddLimitTimeDate/Model";

// 添加限时购
export function addLimitTime(body:any = {}) {
    const userId = Storage.GetData_("userId")
    const token = Storage.GetData_("token")
    let num = 0;
    keyList.forEach(item=>{
        if (!body[item]) {
            num ++;
        }
    })
    if (num){
        if (!body["school"]){
            Toast("请选择社区/学校");
            return Promise.reject();
        }
        Toast("请选择时间");
        return Promise.reject();
    }

    Toast.loading({ duration:0,message:"添加中~~~" })
    return Promise.resolve(addLimitTimeDate({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))

}

// 修改限时购
export function updateLimitTime(body:any = {},oldData:any={}) {
    const userId = Storage.GetData_("userId")
    const token = Storage.GetData_("token")
    let num = 0;
    keyList.forEach(item=>{
        if (body[item] != oldData[item] && body[item] && oldData[item]) {
            num ++;
        }
    })

    if ( num < 1){
        Toast("请修改数据");
        return Promise.reject();
    }
    body.id = oldData.id || ''

    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateLimitTimeDate({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))

}
