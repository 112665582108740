import {filterNum} from "@/util/filter";


export function getHoursMinute(Num = 24) {
    let newList = []
    for ( let i = Num >= 60 ? 0 : 1;i < Num; i ++){
        newList.push({
            text:filterNum(i),
            value: i - 1
        })
    }
    return newList
}

